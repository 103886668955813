import { defineComponent, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');

    var sendMail = function sendMail() {
      window.location.href = 'mailto:reservation.service@dunqian.com';
    };

    return {
      t: t,
      sendMail: sendMail
    };
  }
});