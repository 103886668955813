import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';
import ServiceContent from '@/components/service/content.vue';
export default defineComponent({
  name: 'Service',
  components: {
    HeaderComponent: Header,
    Breadcrumb: Breadcrumb,
    FooterComponent: Footer,
    ServiceContent: ServiceContent
  },
  inject: ['isMobile']
});